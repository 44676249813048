
import {config} from '@/utils'
import { Swipe, SwipeItem } from 'vant';
import homeTitlepc from '@/components/home/homeTitlepc.vue'
import 'vant/lib/index.css';
export default {
    name: 'service',
    components: {
        homeTitlepc,
        Swipe, SwipeItem 
        
    },

    mounted () {
     
    },
    created(){
        this.labData=this.work3[0].children[0].info

    },

    methods: {
        changeStat(v){
            this.lNum=v
        },
        changeCategory(v,e){
            this.cNum=v
            this.work3.forEach(ite => {
                if(ite.name ==e.name && ite.name !='更多行业'){
                    this.labData=ite.children[0].info
                    this.rNum=0
                }
                
            });
        },
        changeCategoryr(v,e){
            this.rNum=v
            this.labData=e.info
        },
        changeActive(v){
            this.active=v
        },
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }


        }
      

    },

    data () {
        return {
            config,
            lNum:1,
            cNum:0,
            rNum:0,
            labData:[],
            Category:[
                
                {
                    name:'家居行业',
                    url:require('./images/hot.png')
                },
                {
                    name:'餐饮行业',
                    url:require('./images/hot.png')
                },
                {
                    name:'服装行业',
                    url:require('./images/hot.png')
                },
                {
                    name:'旅游行业',
                    url:''
                },
                {
                    name:'建材行业',
                    url:''
                },
                {
                    name:'互联网行业',
                    url:''
                },
                {
                    name:'食品行业',
                    url:require('./images/hot.png')
                },
                {
                    name:'化工行业',
                    url:''
                },
                {
                    name:'摄影行业',
                    url:''
                },
                {
                    name:'电器行业',
                    url:require('./images/hot.png')
                },
                {
                    name:'地产行业',
                    url:''
                },
                {
                    name:'汽车行业',
                    url:''
                },
                {
                    name:'健身行业',
                    url:''
                },
                {
                    name:'乐器行业',
                    url:''
                },
                {
                    name:'医疗行业',
                    url:''
                },
                {
                    name:'母婴行业',
                    url:''
                },
                {
                    name:'宠物行业',
                    url:''
                },

                {
                    name:'传媒行业',
                    url:''
                },

                {
                    name:'化妆品行业',
                    url:require('./images/hot.png')
                },

                {
                    name:'珠宝行业',
                    url:''
                },

                {
                    name:'更多行业',
                    url:''
                },
            ],
            work3:[
                {
                    name:'家居行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'07类-家用机械'
                                },
                                {
                                    
                                    tit:'09类-电子电器'
                                },
                                {
                                    
                                    tit:'11类-灯具空调'
                                },
                                {
                                    
                                    tit:'19类-地板门窗'
                                },
                                {
                                    
                                    tit:'20类-家具'
                                },

                                {
                                    
                                    tit:'21类-厨卫用具'
                                },
                                {
                                    
                                    tit:'35类-广告销售'
                                },
                                {
                                    
                                    tit:'42类-装修咨询'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'03类-日化用品'
                                },
                                {
                                    tit:'08类-手工机械',
                                    
                                },
                                {
                                    tit:'24类-床上用品',
                                    
                                },
                                {
                                    tit:'27类-地毯地垫',
                                    
                                },
                                {
                                    tit:'37类-室内装修',
                                    
                                },
                
                                {
                                    tit:'42类-装修咨询',
                                    
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    
                                    tit:'06类-日用五金'
                                },
                                {
                                    
                                    tit:'10类-保健器械'
                                },
                                {
                                    
                                    tit:'39类-物流运输'
                                },
                                {
                                    
                                    tit:'40类-材料加工'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'餐饮行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'35类-饭店管理'
                                },
                                {
                                    
                                    tit:'43类-餐厅饭店'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'07类-厨房器具'
                                },
                                {
                                    tit:'08类-餐具',
                                    
                                },
                                {
                                    tit:'16类-餐巾纸',
                                    
                                },
                                {
                                    tit:'20类-食品装饰',
                                    
                                },
                                {
                                    tit:'21类-炊具器皿',
                                    
                                },
                
                                {
                                    tit:'29类-肉蛋干果',
                                    
                                },
                                {
                                    tit:'30类-方便食品',
                                    
                                },
                
                                {
                                    tit:'31类-蔬果生鲜',
                                    
                                },
                                {
                                    tit:'32类-啤酒饮料',
                                    
                                },
                
                                {
                                    tit:'33类-酒精饮品',
                                    
                                },
                                {
                                    tit:'40类-食品加工',
                                    
                                },
                
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    
                                    tit:'01类-防腐剂'
                                },
                                {
                                    
                                    tit:'02类-食品色素'
                                },
                                {
                                    
                                    tit:'04类-烧烤用炭'
                                },
                                {
                                    
                                    tit:'12类-餐车'
                                },
                                {
                                    
                                    tit:'24类-餐巾桌布'
                                },
                                {
                                    
                                    tit:'39类-储藏运输'
                                },
                                {
                                    
                                    tit:'41类-厨师培训'
                                },
                                {
                                    
                                    tit:'44类-营养饮食'
                                },
                            ]

                        },

                    ]
                    
                },
                
                {
                    name:'服装行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'18类-箱包皮具'
                                },
                                {
                                    
                                    tit:'25类-服装鞋帽'
                                },
                                {
                                    
                                    tit:'35类-广告销售'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'05类-卫生内裤'
                                },
                                {
                                    tit:'09类-防护服装',
                                    
                                },
                                {
                                    tit:'10类-矫形服装',
                                    
                                },
                                {
                                    tit:'14类-珠宝服饰',
                                    
                                },
                                {
                                    tit:'22类-纤维原料',
                                    
                                },
                
                                {
                                    tit:'23类-纺织用纱',
                                    
                                },
                                {
                                    tit:'23类-纺织布料',
                                    
                                },
                                {
                                    tit:'26类-服装配饰',
                                    
                                },
                                {
                                    tit:'40类-服装制作',
                                    
                                },
                                {
                                    tit:'42类-服装设计',
                                    
                                },
                                {
                                    tit:'45类-服装出租',
                                    
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'45类-服装出租',
                                    
                                },
                                {
                                    
                                    tit:'16类-时尚杂志'
                                },
                                {
                                    
                                    tit:'37类-洗涤维护'
                                },
                                {
                                    
                                    tit:'38类-服装直播'
                                },
                                {
                                    
                                    tit:'41类-刊物出版'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'旅游行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'35类-旅游宣传'
                                },
                                {
                                    
                                    tit:'39类-旅游安排'
                                },
                                {
                                    
                                    tit:'41类-导游服务'
                                },
                                {
                                    
                                    tit:'43类-食宿安排'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'06类-金属礼品'
                                },
                                {
                                    tit:'09类-通讯导航',
                                    
                                },
                                {
                                    tit:'14类-珠宝服饰',
                                    
                                },
                                {
                                    tit:'16类-旅游杂志',
                                    
                                },
                                {
                                    tit:'18类-行李箱包',
                                    
                                },
                
                                {
                                    tit:'20类-手工艺品',
                                    
                                },
                                {
                                    tit:'22类-户外帐篷',
                                    
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'09类-通讯导航',
                                    
                                },
                                {
                                    
                                    tit:'28类-户外娱乐'
                                },
                                {
                                    
                                    tit:'25类-旅行服饰'
                                },
                                {
                                    
                                    tit:'45类-安保交友'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'建材行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'06类-金属五金'
                                },
                                {
                                    
                                    tit:'17类-橡胶软管'
                                },
                                {
                                    
                                    tit:'19类-建筑材料'
                                },
                                {
                                    
                                    tit:'35类-特许经营'
                                },
                                {
                                    
                                    tit:'37类-建筑装修'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'01类-粘合剂'
                                },
                                {
                                    tit:'02类-油漆涂料',
                                    
                                },
                                {
                                    tit:'03类-抛光制剂',
                                    
                                },
                                {
                                    tit:'07类-建筑机械',
                                    
                                },
                                {
                                    tit:'08类-手工工具',
                                    
                                },
                
                                {
                                    tit:'09类-警报装置',
                                    
                                },
                                {
                                    tit:'16类-建筑模型',
                                    
                                },
                                {
                                    tit:'39类-物流配送',
                                    
                                },
                                {
                                    tit:'40类-建材加工',
                                    
                                },
                                {
                                    tit:'42类-建筑设计',
                                    
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'04类-照明燃料',
                                    
                                },
                                {
                                    
                                    tit:'36类-租赁管理'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'互联网行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'09类-硬件应用'
                                },
                                {
                                    
                                    tit:'35类-在线推广'
                                },
                                {
                                    
                                    tit:'36类-在线支付'
                                },
                                {
                                    
                                    tit:'38类-在线咨询'
                                },
                                {
                                    
                                    tit:'41类-娱乐服务'
                                },
                                {
                                    
                                    tit:'42类-编程开发'
                                },
                                {
                                    
                                    tit:'45类-社交互动'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'07类-智能家用'
                                },
                                {
                                    tit:'11类-厨卫家电',
                                    
                                },
                                {
                                    tit:'28类-游戏器具',
                                    
                                },
                                {
                                    tit:'37类-设备维护',
                                    
                                },
                                
                            ]

                        },
                    ]
                    
                },
                {
                    name:'食品行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'29类-肉蛋干果'
                                },
                                {
                                    
                                    tit:'30类-方便速食'
                                },
                                {
                                    
                                    tit:'31类-蔬果生鲜'
                                },
                                {
                                    
                                    tit:'32类-啤酒饮料'
                                },
                                {
                                    
                                    tit:'33类-酒精饮品'
                                },
                                {
                                    
                                    tit:'35类-特许经营'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'01类-食品药剂'
                                },
                                {
                                    tit:'02类-食品色素',
                                    
                                },
                                {
                                    tit:'04类-烧烤用炭',
                                    
                                },
                                {
                                    tit:'05类-补药药膳',
                                    
                                },
                                {
                                    tit:'16类-食品包装',
                                    
                                },
                
                                {
                                    tit:'20类-桌椅柜凳',
                                    
                                },
                                {
                                    tit:'21类-炊具器皿',
                                    
                                },
                                {
                                    tit:'34类-香烟制品',
                                    
                                },
                                {
                                    tit:'40类-食品加工',
                                    
                                },
                                {
                                    tit:'43类-餐饮店面',
                                    
                                },
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'05类-补药药膳',
                                    
                                },
                                {
                                    
                                    tit:'21类-炊具器皿'
                                },
                                {
                                    
                                    tit:'03类-厨房日化'
                                },
                                {
                                    
                                    tit:'07类-厨房厨具'
                                },
                                {
                                    
                                    tit:'39类-运输仓储'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'化工行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'01类-化工原料'
                                },
                                {
                                    
                                    tit:'35类-销售代理'
                                },
                                {
                                    
                                    tit:'40类-化学加工'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'02类-颜料树脂'
                                },
                                {
                                    tit:'03类-清洁制剂',
                                    
                                },
                                {
                                    tit:'07类-化工设备',
                                    
                                },
                                {
                                    tit:'08类-研磨器具',
                                    
                                },
                                {
                                    tit:'09类-实验仪器',
                                    
                                },
                
                                {
                                    tit:'17类-纤维材料',
                                    
                                },
                                {
                                    tit:'42类-化学研究',
                                    
                                },
                                
                            ]

                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'14类-贵重金属',
                                    
                                },
                                {
                                    
                                    tit:'16类-化工杂志'
                                },
                                {
                                    
                                    tit:'41类-化工研讨'
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'摄影行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'16类-相册海报'
                                },
                                {
                                    
                                    tit:'35类-加盟连锁'
                                },
                                {
                                    
                                    tit:'40类-影像加工'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'01类-化学用品'
                                },
                                {
                                    tit:'09类-摄影器械',
                                    
                                },
                                {
                                    tit:'20类-画框展台',
                                    
                                },
                                {
                                    tit:'38类-自媒体',
                                    
                                },
                                {
                                    tit:'42类-在线设计',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'25类-摄影服饰',
                                    
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'电器行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'07类-电子机械'
                                },
                                {
                                    
                                    tit:'09类-电子产品'
                                },
                                {
                                    
                                    tit:'11类-厨卫电器'
                                },
                                {
                                    
                                    tit:'35类-广告销售'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'37类-电器维修'
                                },
                                {
                                    tit:'38类-播放设备',
                                    
                                },
                                {
                                    tit:'40类-产品加工',
                                    
                                },
                                {
                                    tit:'41类-产品培训',
                                    
                                },
                                {
                                    tit:'42类-产品系统',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'06类-日用金属',
                                    
                                },
                                {
                                    tit:'21类-厨房洁具',
                                    
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'地产行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'35类-地产宣传'
                                },
                                {
                                    
                                    tit:'36类-房产销售'
                                },
                                {
                                    
                                    tit:'37类-建筑建造'
                                },
                                {
                                    
                                    tit:'42类-室内装修'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'01类-粘合剂'
                                },
                                {
                                    tit:'02类-油漆涂料',
                                    
                                },
                                {
                                    tit:'06类-金属材料',
                                    
                                },
                                {
                                    tit:'19类-建筑材料',
                                    
                                },
                                {
                                    tit:'40类-材料加工',
                                    
                                },
                                {
                                    tit:'41类-置业顾问',
                                    
                                },
                                {
                                    tit:'44类-园艺服务',
                                    
                                },
                                {
                                    tit:'45类-物管社区',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'16类-地产杂志',
                                    
                                },
                                {
                                    tit:'17类-隔热材料',
                                    
                                },
                                {
                                    tit:'20类-家居家具',
                                    
                                },
                                {
                                    tit:'21类-厨房洁具',
                                    
                                },
                                {
                                    tit:'27类-地毯墙纸',
                                    
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'汽车行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'12类-交通运输'
                                },
                                {
                                    
                                    tit:'35类-汽车销售'
                                },
                                {
                                    
                                    tit:'37类-保养维修'
                                },
                                {
                                    
                                    tit:'07类-动力设备'
                                },
                                {
                                    
                                    tit:'09类-通讯导航'
                                },
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'04类-汽车燃料'
                                },
                                {
                                    tit:'11类-车用空调',
                                    
                                },
                                {
                                    tit:'39类-运输租车',
                                    
                                },
                                {
                                    tit:'42类-车载软件',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'22类-汽车拖缆',
                                    
                                },
                                {
                                    tit:'27类-车用脚垫',
                                    
                                },
                                {
                                    tit:'28类-汽车模型',
                                    
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'健身行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'28类-健身器械'
                                },
                                {
                                    
                                    tit:'35类-广告宣传'
                                },
                                {
                                    
                                    tit:'41类-健身训练'
                                },
                                {
                                    
                                    tit:'44类-营养指导'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'10类-矫形器具'
                                },
                                {
                                    tit:'25类-运动服装',
                                    
                                },
                                {
                                    tit:'27类-健身垫子',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'45类-安保服务',
                                    
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'乐器行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'15类-声乐器具'
                                },
                                {
                                    
                                    tit:'35类-在线招生'
                                },
                                {
                                    
                                    tit:'41类-音乐培训'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'09类-电子乐谱'
                                },
                                {
                                    tit:'16类-活页乐普',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'38类-声乐广播',
                                    
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'医疗行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'05类-医药用品'
                                },
                                {
                                    
                                    tit:'10类-医疗器械'
                                },
                                {
                                    
                                    tit:'35类-销售服务'
                                },
                                {
                                    
                                    tit:'42类-医药研究'
                                },
                                {
                                    
                                    tit:'44类-医疗服务'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'07类-制药机械'
                                },
                                {
                                    tit:'11类-消毒设备',
                                    
                                },
                                {
                                    tit:'12类-救护车',
                                    
                                },
                                {
                                    tit:'18类-医用拐杖',
                                    
                                },
                                {
                                    tit:'20类-磁疗枕',
                                    
                                },
                                {
                                    tit:'25类-医疗用衣',
                                    
                                },
                                {
                                    tit:'30类-保健食品',
                                    
                                },
                                {
                                    tit:'40类-药材加工',
                                    
                                },
                                {
                                    tit:'45类-会员管理',
                                    
                                },

                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'01类-医用制剂',
                                    
                                },
                                {
                                    tit:'16类-医疗用纸',
                                    
                                },
                                {
                                    tit:'37类-器械维护',
                                    
                                },
                                {
                                    tit:'41类-健身培训',
                                    
                                },
                                {
                                    tit:'43类-养老院',
                                    
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'母婴行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'03类-母婴日化'
                                },
                                {
                                    
                                    tit:'05类-奶粉尿布'
                                },
                                {
                                    
                                    tit:'10类-奶嘴奶瓶'
                                },
                                {
                                    
                                    tit:'20类-婴儿床枕'
                                },
                                {
                                    
                                    tit:'25类-婴儿衣裤'
                                },
                                {
                                    
                                    tit:'35类-门面招牌'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'12类-婴儿车'
                                },
                                {
                                    tit:'21类-婴儿浴盆',
                                    
                                },
                                {
                                    tit:'28类-儿童玩具',
                                    
                                },
                                {
                                    tit:'41类-幼儿辅导',
                                    
                                },
                                {
                                    tit:'44类-婴儿护理',
                                    
                                },
                                {
                                    tit:'45类-家政服务',
                                    
                                },
                                
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'08类-儿童刀叉',
                                    
                                },
                                {
                                    tit:'09类-婴儿秤',
                                    
                                },
                                {
                                    tit:'18类-婴儿抱袋',
                                    
                                },
                                {
                                    tit:'24类-婴儿被罩',
                                    
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'宠物行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'31类-宠物食品'
                                },
                                {
                                    
                                    tit:'35类-广告销售'
                                },
                                {
                                    
                                    tit:'43类-宠物寄养'
                                },
                                {
                                    
                                    tit:'44类-宠物美容'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'03类-宠物洗浴'
                                },
                                {
                                    tit:'05类-宠物医药',
                                    
                                },
                                {
                                    tit:'12类-宠物座椅',
                                    
                                },
                                {
                                    tit:'18类-宠物用具',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'20类-宠物床窝',
                                    
                                },
                                {
                                    tit:'28类-宠物玩具',
                                    
                                },
                                {
                                    tit:'45类-宠物火化',
                                    
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'传媒行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'09类-软件程序'
                                },
                                {
                                    
                                    tit:'16类-报刊杂志'
                                },
                                {
                                    
                                    tit:'35类-广告推广'
                                },
                                {
                                    
                                    tit:'38类-自媒体'
                                },
                                {
                                    
                                    tit:'41类-文娱服务'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'42类-官方网站'
                                },
                               
                            ]
                        },
                    ]
                    
                },
                {
                    name:'化妆品行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'03类-日化美妆'
                                },
                                {
                                    
                                    tit:'21类-化妆用具'
                                },
                                {
                                    
                                    tit:'35类-广告宣传'
                                },
                                {
                                    
                                    tit:'42类-化妆品研究'
                                },
                                {
                                    
                                    tit:'44类-化妆美容'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'01类-化妆制剂'
                                },
                                {
                                    tit:'05类-含药产品',
                                    
                                },
                                {
                                    tit:'07类-生产设备',
                                    
                                },
                                {
                                    tit:'16类-外观包装',
                                    
                                },
                                {
                                    tit:'20类-化妆家具',
                                    
                                },
                                {
                                    tit:'41类-美容培训',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'09类-美妆软件',
                                    
                                },
                                {
                                    tit:'18类-化妆包袋',
                                    
                                },
                                {
                                    tit:'25类-美容衣物',
                                    
                                },
                                
                            ]

                        },

                    ]
                    
                },
                {
                    name:'珠宝行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'14类-珠宝首饰'
                                },
                                {
                                    
                                    tit:'35类-连锁加盟'
                                },
                                {
                                    
                                    tit:'36类-珠宝估价'
                                },
                                {
                                    
                                    tit:'37类-首饰保养'
                                },
                                {
                                    
                                    tit:'42类-首饰设计'
                                },
                               
                            ]

                        },
                        {
                            title:'关联类别',
                            tooltip:'与该行业强相关的类别',
                            info:[
                                
                                {
                                    
                                    tit:'06类-金属产品'
                                },
                                {
                                    tit:'40类-珠宝加工',
                                    
                                },
                                {
                                    tit:'45类-首饰出租',
                                    
                                },
                            ]
                        },
                        {
                            title:'防御类别',
                            tooltip:'扩大商标使用范围，企业发展必备',
                            info:[
                                {
                                    tit:'19类-石制塑像',
                                    
                                },
                                {
                                    tit:'20类-珍珠贝类',
                                    
                                },
                            ]

                        },

                    ]
                    
                },
                {
                    name:'更多行业',
                    children:[
                        {
                            title:'核心类别',
                            tooltip:'该行业必须注册的类别',
                            info:[
                                {
                                    
                                    tit:'09类-科学仪器'
                                },
                               
                            ]

                        },

                    ]
                    
                },
            ],
            detailedList:[

                {
                   name:"07类-厨房用具"
                },
                {
                    name:"08类-餐具"
                },
                {
                    name:"16类-餐巾纸"
                },
                {
                    name:"20类-食品装饰"
                },
                {
                    name:"21类-饮具器皿"
                },
                {
                    name:"29类-肉蛋干果"
                },
                {
                    name:"30类-方便食品"
                },
                {
                    name:"31类-蔬果生鲜"
                },
                {
                    name:"32类-啤酒饮料"
                },
                {
                    name:"33类-酒精饮品"
                },
                {
                    name:"40类-食品加工"
                },
            ],
            domestic:[//国内
                {
                    name:"发明专利申请",
                    url:''
                },
                {
                    name:"发明专利双享",
                    url:''
                },
                {
                    name:"发明专利无忧",
                    url:require('./images/hot.png')
                },
                
                {
                    name:"发明专利权保",
                    url:require('./images/hot.png')
                },
                {
                    name:"实用新型无忧",
                    url:require('./images/hot.png')
                },
                {
                    name:"实用新型双享",
                    url:''
                },
                {
                    name:"实用新型专享",
                    url:''
                },
                {
                    name:"发明+实用",
                    url:''
                },
                {
                    name:"外观专利专享",
                    url:''
                },
                {
                    name:"外观专利无忧",
                    url:''
                },
                
            ],
            abroad:[//国外
                {
                    name:"美国专利申请",
                    url:require('./images/hot.png')
                },
                {
                    name:"欧洲专利申请",
                    url:''
                },
                {
                    name:"日本专利申请",
                    url:''
                },
                {
                    name:"韩国专利申请",
                    url:''
                },
                {
                    name:"巴黎公约申请",
                    url:''
                },
                {
                    name:"PCT国际申请",
                    url:''
                },
            ],
            copyright:[//版权服务
                {
                    url:require('./images/copyright/c1.png'),
                    name:'软著优享登记',
                    show:'登记不成功退代理费',
                    price:'￥2000',
                    unit:"/件"

                },
                {
                    url:require('./images/copyright/c2.png'),
                    name:'LOGO作品登记',
                    show:'维护品牌形象，防止被假冒',
                    price:'￥598',
                    unit:"/件"

                },
                {
                    url:require('./images/copyright/c3.png'),
                    name:'网站著作权登记',
                    show:'打击网页抄袭者的有效证据',
                    price:'￥800',
                    unit:"/件"

                },
                {
                    url:require('./images/copyright/c4.png'),
                    name:'小说作品登记',
                    show:'保护您的原创小说作品',
                    price:'￥598',
                    unit:"/件"

                },
                {
                    url:require('./images/copyright/c5.png'),
                    name:'摄影作品登记',
                    show:'保护您辛苦拍摄的成果',
                    price:'￥598',
                    unit:"/件"

                },
            ],
            images:[//轮播图
                require('./images/copyright/c6.png'),
                require('./images/copyright/c7.png'),
                require('./images/copyright/c8.png'),
            ],
            active: 0,
            registerList:[
                {
                    name:"文字作品",
                },
                {
                    name:"软著作品",
                },
                {
                    name:"美术作品",
                },
                {
                    name:"摄影作品",
                },
                {
                    name:"影视作品",
                },
                {
                    name:"艺术作品",
                },
                {
                    name:"工程作品",
                },
            ],
            works1:[//文字
                {
                    url:require('./images/copyright/w1.png'),
                    name:'小说',
                    show:'登记版权才能受法律保护',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/w2.png'),
                    name:'论文',
                    show:'登记后，可发表至期刊等平台',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/w3.png'),
                    name:'剧本',
                    show:'登记后，可出售他人拍摄影视作品',
                    price:'￥598',
                    unit:"/件"
                },

                {
                    url:require('./images/copyright/w4.png'),
                    name:'商业计划书',
                    show:'防止商业机密被他人窃取',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/w5.png'),
                    name:'歌词',
                    show:'保护原创，有效避免剽窃、盗窃行为',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/w6.png'),
                    name:'加急登记',
                    show:'提供个性化登记服务，快至当天拿证',
                    price:'￥998',
                    unit:"/件"
                },
               
            ],
            works2:[//软著作品
                {
                    url:require('./images/copyright/r1.png'),
                    name:'网站',
                    show:'打击网页抄袭者的有效证据',
                    price:'￥1600',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/r2.png'),
                    name:'电脑软件',
                    show:'让软件抄袭者赔偿的证据之一',
                    price:'￥1600',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/r3.png'),
                    name:'APP',
                    show:'登记后，可提升公司和项目估值',
                    price:'￥1600',
                    unit:"/件"
                },

                {
                    url:require('./images/copyright/r4.png'),
                    name:'源代码',
                    show:'防止你的源代码被他人复制',
                    price:'￥1600',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/r5.png'),
                    name:'软著优享登记',
                    show:'31个工作日，不成功退全款',
                    price:'￥3000',
                    unit:"/件"
                },
                
               
            ],
            works3:[//美术作品
                {
                    url:require('./images/copyright/m1.png'),
                    name:'LOGO',
                    show:'全面维护品牌形象防止被假冒',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/m2.png'),
                    name:'书画',
                    show:'防止你的书画艺术被他人临摹',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/m3.png'),
                    name:'漫画',
                    show:'保护原创漫画正当授权',
                    price:'￥598',
                    unit:"/件"
                },

                {
                    url:require('./images/copyright/m4.png'),
                    name:'卡通形象',
                    show:'登记后，才能有偿授权他人使用源代码被他人复制',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/m5.png'),
                    name:'平面网页',
                    show:'打击网页抄袭者的有效证据',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/w6.png'),
                    name:'加急登记',
                    show:'提供个性化登记服务，快至当天拿证',
                    price:'￥998',
                    unit:"/件"
                },
                
               
            ],
            works4:[//摄影作品
                {
                    url:require('./images/copyright/s1.png'),
                    name:'照片',
                    show:'避免照片被用于不正当商业、出版、展览',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/s2.png'),
                    name:'写真',
                    show:'保护人物肖像、艺术摄影，免受不正当商业行为',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/s3.png'),
                    name:'人物',
                    show:'避免照片被用于不正当商业、出版、展览',
                    price:'￥598',
                    unit:"/件"
                },

                {
                    url:require('./images/copyright/s4.png'),
                    name:'动物',
                    show:'避免照片被用于不正当商业、出版、展览',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/s5.png'),
                    name:'建筑',
                    show:'避免作品被用于不正当商业、出版、展览',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/s6.png'),
                    name:'风景',
                    show:'避免照片被用于不正当商业、出版、展览',
                    price:'￥598',
                    unit:"/件"
                },
                
               
            ],
            works5:[//影视作品
                {
                    url:require('./images/copyright/y1.png'),
                    name:'电视剧',
                    show:'控告他人抢播、盗播的有效证据',
                    price:'￥68',
                    unit:"/集起"
                },
                {
                    url:require('./images/copyright/y2.png'),
                    name:'宣传片',
                    show:'控告他人盗用创意的有效证据',
                    price:'￥798',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/y3.png'),
                    name:'动画片',
                    show:'防止动画元素被盗用',
                    price:'￥798',
                    unit:"/件"
                },

                {
                    url:require('./images/copyright/y4.png'),
                    name:'电影',
                    show:'登记后，可有偿授权他人播放',
                    price:'￥1998',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/y5.png'),
                    name:'综艺节目',
                    show:'防止节目流程被抄袭、模仿',
                    price:'￥798',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/y6.png'),
                    name:'视频短片',
                    show:'保护视频不被非法篡改或翻录',
                    price:'￥798',
                    unit:"/件"
                },
                
               
            ],
            works6:[//艺术作品
                {
                    url:require('./images/copyright/p1.png'),
                    name:'歌曲',
                    show:'打击恶意翻唱、改编行为的有效证据',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/p2.png'),
                    name:'音乐',
                    show:'登记后，可有偿授权指定平台传播',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/p3.png'),
                    name:'铃声',
                    show:'有效避免剽窃、盗窃行为',
                    price:'￥598',
                    unit:"/件"
                },

                {
                    url:require('./images/copyright/p4.png'),
                    name:'舞蹈',
                    show:'登记后，可有偿授权指定平台传播',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/p5.png'),
                    name:'曲艺',
                    show:'登记后，可有偿授权指定平台传播',
                    price:'￥598',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/p6.png'),
                    name:'戏剧',
                    show:'登记后，可有偿授权指定平台传播',
                    price:'￥598',
                    unit:"/件"
                },
                
               
            ],
            works7:[//工程作品
                {
                    url:require('./images/copyright/g1.png'),
                    name:'工程设计图纸',
                    show:'向侵权者追讨赔偿金的有效依据',
                    price:'￥898',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/g2.png'),
                    name:'产品设计图',
                    show:'确定版权归属实现版权收入',
                    price:'￥898',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/g3.png'),
                    name:'地图',
                    show:'控告侵权者公然侵权的有效证据',
                    price:'￥898',
                    unit:"/件"
                },

                {
                    url:require('./images/copyright/g4.png'),
                    name:'电路示意图',
                    show:'确认电路示意图著作权',
                    price:'￥898',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/g5.png'),
                    name:'服装设计图',
                    show:'防止服装样式被抄袭',
                    price:'￥898',
                    unit:"/件"
                },
                {
                    url:require('./images/copyright/w6.png'),
                    name:'加急登记',
                    show:'根据用户需求提供个性化登记服务，快至当天拿证',
                    price:'￥1098',
                    unit:"/件"
                },
                
               
            ],


           
            
        }
    }
}
